export const defaultConfig = {
  logo: "XIMI国际订货商城",
  headerNavData: {
    active: 0,
    list: [
      {
        index: "1",
        name: "首页",
        subAction: "1-1",
        subList: [
          {
            index: "1-1",
            name: "后台首页",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/home/index",
          },
          {
            index: "1-2",
            name: "广告管理",
            icon: "el-icon-eleme",
          },
          {
            index: "1-3",
            name: "公告管理",
            icon: "el-icon-message-solid",
            pathName: "/admin/home/noticeMessage",
          },
        ],
      },
      {
        index: "2",
        name: "商品",
        subAction: "2-1",
        subList: [
          {
            index: "2-1",
            name: "商品管理",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/goodsManagement",
          },
          {
            index: "2-2",
            name: "折扣产品",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/discount",
          },
          {
            index: "2-3",
            name: "优惠券",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/coupons",
          },
          {
            index: "2-4",
            name: "满减产品",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/less",
          },
          {
            index: "2-5",
            name: "商品分类",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/goodsType",
          },
          {
            index: "2-6",
            name: "系列产品",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/series",
          },
          {
            index: "2-7",
            name: "占用查询",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/occupancyQuery",
          },
          {
            index: "2-8",
            name: "认证文件",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/certificationDocument",
          },
          {
            index: "2-9",
            name: "认证类型",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/goods/certificationType",
          },
        ],
      },
      {
        index: "3",
        name: "订单",
        subAction: "3-1",
        subList: [
          {
            index: "3-1",
            name: "购物车列表",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/order/shoppingCartList",
          },
          {
            index: "3-2",
            name: "订单列表",
            icon: "el-icon-eleme",
            pathName: "/admin/order/orderList",
          },
        ],
      },
      {
        index: "4",
        subAction: "4-1",
        name: "用户",
        subList: [
          {
            index: "4-1",
            name: "用户列表",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/user/userList",
          },
        ],
      },
      {
        index: "5",
        subAction: "5-1",
        name: "权限管理",
        subList: [
          {
            index: "5-1",
            name: "管理员列表",
            icon: "el-icon-platform-eleme",
            pathName: "/admin/authManage/superManage",
          },
          {
            index: "5-2",
            name: "查看管理组",
            icon: "el-icon-eleme",
            pathName: "/admin/authManage/manageGroupList",
          },
          {
            index: "5-3",
            name: "权限列表",
            icon: "el-icon-delete-solid",
            pathName: "/admin/authManage/rulesList",
          },
        ],
      },
    ],
  },
};
