<template>
  <el-container style="position: absolute; top: 0; bottom: 0; right: 0; left: 0">
    <el-header>
      <a style="margin-right: auto">{{ defaultConfig.logo }}</a>
      <el-menu style="" :default-active="headerNavData.active | numToString" class="el-menu-demo"
               mode="horizontal" @select="headerSelect" background-color="#545c64" text-color="#fff"
               active-text-color="#ffd04b">
        <el-menu-item v-for="(item, key) in headerNavData.list" :key="key" :index="key | numToString">
          {{ item.name }}
        </el-menu-item>
        <el-submenu index="99-99">
          <template slot="title">
            <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png">
            </el-avatar>
            {{ this.username }}
          </template>
          <el-menu-item index="99-99-1" @click="updateAdminFlag = true">修改</el-menu-item>
          <el-menu-item index="99-99-2">退出</el-menu-item>
          <el-dialog
            title="编辑信息"
            :visible.sync="updateAdminFlag"
            width="35%">
            <el-form ref="findform" :model="updateAdminForm" label-width="90px" class="find-form" size="40">
              <el-form-item label="新密码">
                <el-input type="password" placeholder="新密码" class="find-input"
                          v-model="updateAdminForm.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="updateAdminFlag = false">取消</el-button>
                <el-button type="primary" @click="handleUpdateAdmin">提交</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-submenu>
      </el-menu>
    </el-header>
    <el-container style="height: 80%">
      <!-- 左边布局  -->
      <el-aside width="200px" style="background: white">
        <el-menu style="height: 100%" :default-active="leftMenuAction" class="el-menu-vertical-demo"
                 @select="leftSelect">
          <el-menu-item :index="index | numToString" v-for="(item, index) in subLeftMenus"
                        :key="index | numToString">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <!-- 右边布局 -->
      <el-main style="background: white; padding: 0; border-left: 1px solid #ccc">
        <div style="border-bottom: 1px solid #ccc; padding: 20px">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!--
            <el-breadcrumb-item v-for="(item, index) in bran" :to="{ path: item.path }" :key="index">
              {{ item.title }}
            </el-breadcrumb-item>
            -->
            <el-breadcrumb-item> 订货商城</el-breadcrumb-item>

            <el-breadcrumb-item> 后台管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <router-view></router-view>
        <el-backtop target=".el-main" :bottom="100">
          <div style="
                 {
                  height: 100%;
                  width: 100%;
                  background-color: #f2f5f6;
                  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
                  text-align: center;
                  line-height: 40px;
                  color: #1989fa;
                }
              ">
            UP
          </div>
        </el-backtop>
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
import common from "@/common/mixins/common.js";
import { defaultConfig } from "./config";
import { mapState } from "vuex";

export default {
  mixins: [common],
  data() {
    return {
      headerNavData: [],
      bran: [], //面包屑
      defaultConfig,
      updateAdminFlag: false,
      updateAdminForm:{
        password:''
      }
    };
  },
  watch: {
    $route(to, from) {
      localStorage.setItem(
        "navAction",
        JSON.stringify({
          top: this.headerNavData.active,
          left: this.leftMenuAction
        })
      );
      this.getNavRouter();
    }
  },
  created() {
    this.isLogin();
    //初始化菜单 begin
    //this.headerNavData = this.defaultConfig.headerNavData //也可以读取接口来获取菜单
    this.headerNavData = JSON.parse(
      window.sessionStorage.getItem("headerNavData")
    );
    //初始化菜单 end

    this.getNavRouter();
    this.initUserFunc();
    this.__initNavShow();
    this.loadUsername();
    document.title = "ximi-订货管理系统";
  },
  computed: {
    ...mapState({
      user: (state) => state.admin.user
    }),
    leftMenuAction: {
      get() {
        return this.headerNavData.list[this.headerNavData.active].subAction || "0";
      },
      set(val) {
        this.headerNavData.list[this.headerNavData.active].subAction = this.headerNavData.list[this.headerNavData.active].subAction ?
          val :
          undefined;
      }
    },
    subLeftMenus() {
      return this.headerNavData.list[this.headerNavData.active].subList; //当前选中的头部菜单
    }
  },
  methods: {
    __initNavShow() {
      let b = localStorage.getItem("navAction");
      if (b) {
        b = JSON.parse(b);
        this.headerNavData.active = b.top;
        this.leftMenuAction = b.left;
      }
    },
    //初始化用户登录信息
    initUserFunc() {
      this.$store.commit("initUser");
    },
    //判断是否登录
    isLogin() {
      if (!window.sessionStorage.getItem("user")) {
        this.$router.push("/adminLogin");
      }
    },
    //头部选择
    headerSelect(key, keyPath) {
      if (key === "99-99-1") {
        this.updateAdminFlag = true
        return
      }
      if (key === "99-99-2") {
        //do ...... 需要去掉登录凭证，暂时跳过，后期处理
        this.$router.push("/adminLogin"); //退出登录
        localStorage.removeItem("navAction") //重置默认按钮
        return;
      }
      if (this.headerNavData.active != key) {
        this.headerNavData.active = key;
        this.leftMenuAction = "0"; //初始化默认为第一个选中

        //处理切换头部导航的时候默认跳转到对应的参数 begin
        if (this.subLeftMenus.length > 0) {
          this.$router.push(this.subLeftMenus[0].pathname);
        }
        //处理切换头部导航的时候默认跳转到对应的参数 end
      }
      this.getNavRouter();
    },

    //左边选择
    leftSelect(key) {
      //if (this.leftMenuAction != key) {
      this.leftMenuAction = key; //左边选中的值
      let headerSelectMenu = this.subLeftMenus; //当前选中菜单

      this.$router.push(headerSelectMenu[key].pathname);
      //}
    },

    //获取面包屑导航
    getNavRouter() {
      let b = this.$route.matched.filter((v) => v.name);
      let arr = [];

      b.forEach((v) => {
        if (v.name === "home/index" || v.name === "layout") {
          return;
        } else {
          arr.push({
            name: v.name,
            path: v.path,
            title: v.meta.title
          });
        }
      });
      if (arr.length > 0) {
        arr.unshift({
          path: "/home/index",
          title: "后台首页"
        });
      }
      this.bran = arr;
    },
    //获取用户名
    loadUsername() {
      const user = window.sessionStorage.getItem("user");
      const userArr = JSON.parse(user);
      this.username = userArr.admin_info.user_name;
    },
    handleUpdateAdmin(){

      const user = window.sessionStorage.getItem("user");

      const userArr = JSON.parse(user);

      this.$axios.put("/a1/admin/" + userArr.admin_info.id,{
        password:this.updateAdminForm.password
      }).then(() => {
        this.updateAdminFlag = false
        this.$message.success("修改成功,正在跳转登录页,请重新登录。")

        setTimeout(() => {
          sessionStorage.clear()
          this.$router.push({path:"/adminLogin"})
        },800)

      })

    }
  }
};
</script>

<style>
.el-header {
  background-color: rgb(84, 92, 100);
  color: white;
  display: flex;
  align-items: center;
}

.el-menu {
  border-right: 0;
}
</style>
